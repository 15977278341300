import React, { useState } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
//import 'antd/dist/antd.css';
import {
  Form,
  Input,
  InputNumber,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
} from 'antd';
import ContactFromWrapper, { SectionMainWrapper } from './contact.style';
//import Button from 'common/src/components/Button';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import { updateUserInfo } from '../../../../actions';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.us';
// import styles from "./reasons.module.css"
import './reason.css';
//import { Radio } from 'antd';
import GoogleAddressSearch2 from '../../GoogleAutoComplete';
import PdfElectronicCommunicationPolicy from '../../../../documents/electronicCommunicationsPolicy.pdf';
import PdfTermsOfService from '../../../../documents/termsOfService.pdf';
import PdfPrivacyPolicy from '../../../../documents/privacyPolicy.pdf';

const { Option } = Select;
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 2,
    },
  },
};

const TellUsAboutYourCoborrower = ({
  sectionWrapper,
  row,
  contactForm,
  secTitleWrapper,
  button,
  note,
  title,
  refiReasons,
  description,
  currentUser,
  updateUserInfo,
  userToken,
  showLoader,
  hideLoader,
  loading,
}) => {
  const [form] = Form.useForm();
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [mobilePhone, setMobilePhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [SocialSecurityNumber, setSocialSecurityNumber] = useState(null);
  const [googleAddress2, setGoogleAddress2] = useState(null);
  const [propertyAddress, setPropertyAddress] = useState(null);
  const [streetValue, setStreetValue] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [zipCode, setZipCode] = useState(null);
  const [acceptedTerms, setAcceptedTerms] = useState(null);

  const handleChangeFirstName = (e) => {
    console.log('residence radio checked', e.target.value);
    setFirstName(e.target.value);
  };

  const handleChangeLastName = (e) => {
    console.log('property radio checked', e.target.value);
    setLastName(e.target.value);
  };

  const handleChangeMobilePhone = (e) => {
    console.log('Value', e.target.value);
    setMobilePhone(e.target.value);
  };

  const handleChangeEmail = (e) => {
    console.log('Value', e.target.value);
    setEmail(e.target.value);
  };

  const handleSocialSecurityNumber = (e) => {
    console.log('Value', e.target.value);
    setSocialSecurityNumber(e.target.value);
  };

  const handlePropertyAddress = (e) => {
    console.log('propertyAddress', e.target.value);
    setPropertyAddress(e.target.value);
  };

  const handleState = (e) => {
    console.log('state', e.target.value);
    setState(e.target.value);
  };

  const handleCity = (e) => {
    console.log('city', e.target.value);
    setCity(e.target.value);
  };

  const handleZipCode = (e) => {
    console.log('zip code', e.target.value);
    setZipCode(e.target.value);
  };

  const onGoogleAddressChangeValue = (val) => {
    console.log('on change val', val);
    console.log('on change val.state', val.state);
    setGoogleAddress2(val);
    setPropertyAddress(val.street_address + ' ' + val.route);
    setState(val.state);
    setCity(val.city);
    setZipCode(val.zip_code);
    form.setFieldsValue({
      propertyAddress: val.street_address + ' ' + val.route,
      state: val.state,
      city: val.city,
      zipCode: val.zip_code,
    });
  };

  const onChangeValuePrimary = (val) => {
    console.log('on change val', val);
    console.log('on change val.state', val.state);
    setGoogleAddress2(val);
    let primaryAddress = val.street_address + ' ' + val.route;
    setStreetValue(val.street_address + ' ' + val.route);
    form.setFieldsValue({
      primaryResidencePropertyAddress: primaryAddress,
      primaryResidenceState: val.state,
      primaryResidenceCity: val.city,
      primaryResidenceZipCode: val.zip_code,
    });
  };

  const onChangeInputPrimary = (val) => {
    console.log('onChangeInputPrimary', val);

    //   form.setFieldsValue({
    // 	subjectPropertyAddress: val
    //   });
    setStreetValue(val);
  };

  const handleAcceptedTerms = (e) => {
    console.log('Value', e.target.checked);
    setAcceptedTerms(e.target.checked);
  };

  const onFinish = async (val) => {
    console.log(firstName, mobilePhone);
    Object.assign(currentUser.coBorrower, {
      firstName: firstName,
      lastName: lastName,
      contactPointTelephoneValue: mobilePhone,
      contactPointRoleType: 'Mobile',
      addressLineText: streetValue,
      cityName: val.primaryResidenceCity,
      stateCode: val.primaryResidenceState,
      postalCode: val.primaryResidenceZipCode,
      email: email,
      taxPayerIdentifierType: 'SocialSecurityNumber',
      taxpayerIdentifierValue: SocialSecurityNumber,
    });
    console.log('onFinish values ', currentUser);
    updateUserInfo(currentUser, userToken);
    navigate('/creditIncomeCoBorrower');
  };

  //   require('./reasons.module.css');//
  return (
    <SectionMainWrapper>
      <Box {...sectionWrapper}>
        <Container>
          <Box {...row}>
            <ContactFromWrapper>
              <Form
                //{...formItemLayout}
                form={form}
                layout="vertical"
                name="register"
                onFinish={onFinish}
                scrollToFirstError
                style={{ width: '90%' }}
              >
                <Heading
                  content={'Tell us about your co-borrower.'}
                  fontWeight="bold"
                  {...title}
                />

                <Text fontWeight="bold" {...description} />

                <div className="homeValue">
                  <Form.Item
                    name="firstName"
                    label="First name"
                    onChange={handleChangeFirstName}
                    /* rules={[
                        {
                          required: true,
                          message: '*Required',
                        },
                      ]} */
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="lastName"
                    label="Last name"
                    onChange={handleChangeLastName}
                    /* rules={[
                        {
                          required: true,
                          message: '*Required',
                        },
                      ]} */
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="mobilePhone"
                    label="Mobile phone"
                    onChange={handleChangeMobilePhone}
                    /* rules={[
                        {
                          required: true,
                          message: '*Required',
                        },
                      ]} */
                  >
                    <Cleave
                      className="ant-input"
                      placeholder="888 888 8888"
                      options={{ phone: true, phoneRegionCode: 'US' }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    label="E-mail"
                    onChange={handleChangeEmail}
                    rules={[
                      {
                        type: 'email',
                        message: 'Please enter a valid E-mail',
                      },
                      {
                        required: true,
                        message: 'Please input your E-mail',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="socialSecurityNumber"
                    label="Social security number"
                    onChange={handleSocialSecurityNumber}
                    rules={[
                      {
                        /* required: true,
                        min: 11,
                        message: '*Required', */
                      },
                    ]}
                  >
                    <Cleave
                      className="ant-input"
                      options={{
                        delimiters: ['-', '-'],
                        numericOnly: true,
                        uppercase: true,
                        blocks: [3, 2, 4],
                      }}
                    />
                  </Form.Item>

                  <Heading
                    content={'Current address'}
                    fontWeight="bold"
                    {...title}
                  />

                  <GoogleAddressSearch2
                    id="primaryResidencePropertyAddress"
                    name="primaryResidencePropertyAddress"
                    onChangeInput={onChangeInputPrimary}
                    onChangeValue={onChangeValuePrimary}
                  />
                  <p></p>

                  {googleAddress2 !== null && (
                    <Form.Item
                      name="apartmentNumber"
                      label="Apartment, Suite, Unit"
                    >
                      <Input autoComplete="new-password" />
                    </Form.Item>
                  )}

                  {googleAddress2 !== null && (
                    <Form.Item
                      name="primaryResidenceCity"
                      label="Primary Residence City"
                      rules={[
                        {
                          required: true,
                          message: '*Required',
                        },
                      ]}
                    >
                      <Input autoComplete="new-password" />
                    </Form.Item>
                  )}

                  {googleAddress2 !== null && (
                    <Form.Item
                      name="primaryResidenceState"
                      label="Primary Residence State abbreviation"
                      rules={[
                        {
                          required: true,
                          message: '*Required',
                        },
                        {
                          max: 2,
                          message: 'Please only use two characters',
                        },
                        {
                          min: 2,
                          message: 'Please input a valid state',
                        },
                        {
                          pattern: /[a-zA-Z]+/,
                          message: 'Letters only',
                        },
                      ]}
                    >
                      <Input autoComplete="new-password" />
                    </Form.Item>
                  )}
                  {googleAddress2 !== null && (
                    <Form.Item
                      name="primaryResidenceZipCode"
                      label="Primary Residence Zip Code"
                      rules={[
                        {
                          required: true,
                          message: '*Required',
                        },
                        {
                          pattern: /^\d{5}(?:[-\s]\d{4})?$/,
                          message: 'Please enter a valid zip code',
                        },
                        {
                          min: 5,
                          message: '*Must be at least 5 digits',
                        },
                      ]}
                    >
                      <Input autoComplete="new-password" />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    onChange={handleAcceptedTerms}
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject('Should accept agreement'),
                      },
                    ]}
                  >
                    <Checkbox>
                      I, {firstName} {lastName}, the co-borrower, authorize Best
                      Finance to verify my credit. I’ve also read and agreed to
                      Best Finance’s
                      <a href={PdfTermsOfService} target="_blank">
                        {' '}
                        <u>Terms of Use</u>{' '}
                      </a>
                      ,
                      <a href={PdfPrivacyPolicy} target="_blank">
                        <u>Privacy Policy</u>{' '}
                      </a>
                      , and
                      <a
                        href={PdfElectronicCommunicationPolicy}
                        target="_blank"
                      >
                        {' '}
                        <u>Consent to Receive Electronic Loan Documents</u>{' '}
                      </a>
                      , and consent to a credit inquiry and income verification.
                      I understand I am providing my prior express written
                      consent for Best Finance to deliver calls or text messages
                      to my phone number provided above, including for marketing
                      purposes and using an automated telephone dialing system,
                      even if my telephone number is currently listed on a
                      federal, state, internal or corporate Do-Not-Call list. I
                      understand that my consent is not required to make a
                      purchase. <p></p>
                    </Checkbox>
                  </Form.Item>
                </div>

                <Form.Item {...tailFormItemLayout}>
                  <p></p>
                  <p></p>
                  <div className="containerClassReasons">
                    <Button
                      type="primary"
                      title="Next"
                      htmlType="submit"
                      {...button}
                    >
                      Continue
                    </Button>
                  </div>
                </Form.Item>
                {/*  <li>
				<Button
				title={'Next'}
				onClick={onFinish}
				/>
</li> */}
              </Form>
            </ContactFromWrapper>
          </Box>
        </Container>
      </Box>
    </SectionMainWrapper>
  );
};

TellUsAboutYourCoborrower.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  contactForm: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  button: PropTypes.object,
  note: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  colornote: PropTypes.object,
};

TellUsAboutYourCoborrower.defaultProps = {
  sectionWrapper: {
    id: 'contact_section',
    as: 'section',
    pt: ['8px', '80px', '80px', '80px'],
    pb: ['0', '80px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['40px', '40px', '40px'],
    p: ['0 15px', 0, 0, 0, 0],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: `${2}`,
    letterSpacing: '0.15em',
    fontWeight: `${6}`,
    color: 'primary',
    mb: `${3}`,
  },
  secHeading: {
    textAlign: 'center',
    fontSize: [`${6}`, `${8}`],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: `${0}`,
  },
  row: {
    flexBox: true,
    justifyContent: 'center',
  },
  contactForm: {
    width: [1, 1, 1, 1 / 2],
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    fontColor: 'white',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'secondaryWithBg',
    minHeight: 'auto',
    height: '40px',
    marginRight: '8px',
    text: {
      fontSize: 16,
      lineHeight: 21,
      fontWeight: 'bold',
      letterSpacing: 0.25,
      color: 'white',
    },
  },
  note: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  colornote: {
    fontSize: '16px',
    fontWeight: '500',
    color: 'rgb(106, 82, 253)',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  title: {
    fontSize: ['18px', '28px', '32px', '32px', '37px'],
    fontWeight: '600',
    color: '#000000',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.31',
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
  label: {
    fontSize: '28px',
  },

  description: {
    content:
      "We will use this information to review your co-borrower's credit score and history so that we can provide you with real, accurate loan options.",
    fontSize: ['10px', '12px', '18px', '18px', '20px'],
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    //textAlign: ['center', 'center'],
  },
};

const mapStateToProps = (state) => ({
  currentUser: state.root.currentUser?.user,
  userToken: state.root.currentUser?.token,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserInfo: (currentUser, userToken) =>
      dispatch(updateUserInfo(currentUser, userToken)),
  };
};

const TellUsAboutYourCoborrowerRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(TellUsAboutYourCoborrower);

export default TellUsAboutYourCoborrowerRedux;
